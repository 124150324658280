import React from 'react'
// Icons
// import DateIcon from "images/calendar.svg"
// import TimeIcon from "images/watch.svg"
// Styles
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import sanHTML from 'functions/sanitizeHTML'
import { CustomContainer } from "styles/CustomContainer.styles"

import ActivityPageMap from 'components/CustomGoogleMaps/ActivityPageMap.js'
import ActivityDetailsTable from 'components/ActivityDetailsTable'

import { ActivityTypeMark } from "styles/GlobalStyles.js"

import ActivityGoogleMap from 'components/CustomGoogleMaps/ActivityGoogleMap.js'

// render the details table for the activity content type (for both 'teaser' and 'detailed' modes.)
// if a map is available, include it.
const ActivityMapContainer = ({ acti }) => {
    console.log ('(#423423432) activity: ', acti)
    // const title       = acti.title

    // get activity description
    var desc
    if (acti.body) {
        desc = acti.body.processed
    } else {
        desc = '[no description]'
    }

    // get activity description
    if (acti.field_health_hazards) {
        var hazards = acti.field_health_hazards
        hazards = hazards.split("\n").join("\n<br/>")
        // hazards = ''
    }

    const headerText = acti.title

    // determine if map should be displayed (or not)
    var mapOn = ActivityGoogleMap.isMapAvailable(acti)
    console.log ('(#1234wd) mapOn:', mapOn)

    var colLG, layout
    if (mapOn) {
        colLG = 4
        layout = 'all_12s'
    } else {
        colLG = 12
        layout = 'compact'
    }
    console.log ('(#13efc2) colLG:', colLG)
    
    return (
        <CustomContainer style={{width: "100%"}}>
            <Row>
                {mapOn &&
                <Col xs={12} md={12} lg={8}>
                    <ActivityPageMap activity={acti} />
                </Col>
                }
                <Col xs={12} md={12} lg={colLG}>
                    <ActivityDetailsTable acti={acti} detailLevel={"all"} layout={layout} />
                </Col>
            </Row>

            <Row>
                <Col className="Right" xs={12} md={12} lg={12}>
                    <h3>Description:</h3>
                    <div dangerouslySetInnerHTML={{ __html: sanHTML(desc) }} />
                </Col>
            </Row>

            { hazards && 
            <Row>
                <Col className="Right" xs={12} md={12} lg={12}>
                    <h3>Hazards:</h3>
                    <div dangerouslySetInnerHTML={{ __html: sanHTML(hazards) }} />
                </Col>
            </Row>
            }
        </CustomContainer>
    )
};

export default ActivityMapContainer;