import React, { useState } from "react"
import { StartEndLocationMarkers, OutputPhotographMarkers } from 'components/OurGoogleMap/GoogleMapHelpers.js'

import OurGoogleMap, { OurInfoWindow } from "components/OurGoogleMap"

import { withScriptjs, withGoogleMap } from "react-google-maps"

// display <GoogleMap>, <Marker> (for photographs) and <InfoWindow> items)
// (note: the props is sent in via the prop on the "WrappedMap" component)
const CustomMap = ( {activity} ) => {
  
  // when it's set, it will display an infoWindow panel on the map with custom information.
  const [infoWindowData, setinfoWindowData] = useState(null);
  
  const center = activity.field_acti_google_maps_center
  const mapZoom = Number.parseInt(activity.field_acti_google_map_zoom_level)

  const photos = activity.relationships.field_step_photographs
  const actiSlugBase = activity.fields.slug
  console.log('(#44fw4feacwscd4). activity: ', activity)

  return (
    <OurGoogleMap zoom={mapZoom} centerLat={center.lat} centerLng={center.lng}>

      <StartEndLocationMarkers activity={activity} setinfoWindowData={setinfoWindowData} />
      <OutputPhotographMarkers photos={photos} slugBase={actiSlugBase} setinfoWindowData={setinfoWindowData} />
      <OurInfoWindow infoWindowData={infoWindowData} setinfoWindowData={setinfoWindowData} />
    </OurGoogleMap>
  )
}


// the map you build is put in here, it links it to the Google Maps library code.
// this is also HOW you pass custom data into your map (via a parameter)
export const ActivityPageMap = ( {activity} ) => {
  var WrappedMap = withScriptjs(withGoogleMap(CustomMap))

  return (
    <div style={{width: '100%', height: '500px'}}>
                    
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawings,places&key=` + process.env.GATSBY_GOOGLE_MAPS_API_KEY + ``}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
        activity={activity}
      />
    </div>
  )
}
export default ActivityPageMap
