import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"

import PhotoTeaser from 'components/PhotoTeaser'
import ActivityMapContainer from 'components/ActivityMapContainer'
import SaveButtonContainer from "../components/SavedItems/SaveButtonContainer"

const IndexPage = ({ data, pageContext }) => {

  console.log('(#45tgveun43z), data: ', data )
  
  const acti        = data.allNodeActivity.nodes[0]
  
  const title       = acti.title
  console.log('in ActivityPlan component (#sdfw4feacwscd4). Activity: ', acti)
  const photos      = acti.relationships.field_step_photographs

  return (
    <Layout>
      <SEO title={title} />
      <main>
        <SaveButtonContainer node={acti}>
          <ActivityMapContainer acti={acti}></ActivityMapContainer>
        </SaveButtonContainer>

        {photos.map((photo, index) => {
          console.log ('current photo: ', photo)
          console.log ('counter: ', index)

          return (
            <div key={photo.id}>
              <PhotoTeaser step={index+1} photo={photo} />
              
            </div>
          )
        })}

      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query FindActivityByNId ($nid: Int) {
  allNodeActivity(filter: {drupal_internal__nid: {eq: $nid}}) {
    nodes {
      drupal_id
      __typename
      drupal_internal__nid
      field_activity_distance_in_km
      field_activity_return_type
      field_is_activity_plan_available
      field_physical_difficulty
      field_recommended_time_in_situ
      field_terrain_description
      field_total_time_recommended
      field_travel_time_one_way
      field_acti_google_map_zoom_level
      field_acti_google_maps_center {
        lat
        lng
        value
      }
      fields {
        slug
      }
      body {
        processed
      }
      field_health_hazards
      id
      title
      relationships {
        field_activity_start_locat {
          title
          fields {
            slug
          }
          relationships {
            field_state {
              title
              relationships {
                field_states_country {
                  title
                  drupal_id
                }
              }
              drupal_id
            }
          }
        }
        field_activity_destination {
          drupal_internal__nid
          title
          field_location_lng_lat {
            lat
            lng
            value
          }
        }
        field_activity_finish_locat {
          drupal_internal__nid
          title
          field_location_lng_lat {
            lat
            lng
            value
          }
        }
        field_activity_start_locat {
          drupal_internal__nid
          title
          field_location_lng_lat {
            lng
            lat
            value
          }
        }
        
        field_step_photographs {
          drupal_internal__nid
          id
          fields {
            slug
          }
          title
          field_photo_photographer {
            title
            uri
          }
          body {
            processed
          }
          field_is_4x3_available
          relationships {
            field_photo_images {
              id
              drupal_internal__fid
              localFile {
                childImageSharp {
                  id
                  fluid(
                    maxWidth: 600, maxHeight: 750, cropFocus: CENTER
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            field_photographer {
              drupal_internal__nid
              id
              title
              fields {
                slug
              }
            }
          }
        }
      }
    }
  }
}
`