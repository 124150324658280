import React from 'react';

import { PhotoDetails } from "./styles.js"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import sanHTML from 'functions/sanitizeHTML'

import PhotographerResolver from 'functions/PhotographerResolver'

const PhotoDetailsTable = ({photo, detailLevel, layout}) => {
    console.log('in PhotoDetailsTable component #4g90fwr. photo = ', photo)
    var left_lg, right_lg, left_md, right_md, left_sm, right_sm

    if (layout == 'large') {
        left_lg   = 3
        right_lg  = (12 - left_lg)
    
        left_md   = 4
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }
    
    if (layout == 'compact') {
        left_lg   = 6
        right_lg  = (12 - left_lg)
    
        left_md   = 6
        right_md  = (12 - left_md)
    
        left_sm   = 12
        right_sm  = 12
    }

    var photoDesc
    // get photo description
    if (photo.body) {
        photoDesc = photo.body.processed
    } else {
        photoDesc = '[no description]'
    }

    // console.log('zzz', photographer)

    const data = {
        'Photographer': PhotographerResolver(photo)
    };
    
    const Details = ({ data }) => {
        console.log ('photo detail table fields: ', data)
        return Object.entries(data).map(([k, v]) => {
            if (!v) {
                return null
            }
            return (
            <Row key={k}>
                <Col className="Left" xs={left_sm} md={left_md} lg={left_lg}>
                    {k}:
                </Col>
                <Col className="Right" xs={right_sm} md={right_md} lg={right_lg}>
                    {v}
                </Col>
            </Row>
        )});
    }
    
    // const rows = [{"cat": 'hello'}, {"cat": '2'}];
    
    return (
        <PhotoDetails>
            <Details data={data} />

            <Row>
                <Col className="Description" xs={12} md={12} lg={12}>
                    <h3>Description:</h3>
                    <div dangerouslySetInnerHTML={{ __html: sanHTML(photoDesc) }} />
                </Col>
            </Row>
        </PhotoDetails>
        
    )
};

export default PhotoDetailsTable;