import styled from "styled-components"
import { CustomContainer } from 'styles/CustomContainer.styles'
import { colors } from 'styles/GlobalStyles.js'


export const PhotoDetails = styled(CustomContainer)`
    .Left {
        background-color: ${colors.legendPhotograph};
        margin: 0px 0px 3px 0px;
        color: #000 !important;
    }
    
    .Right {
        color: #ddd;
    }
    .Left, .Right {
        padding: 0px 9px;
    }

    .row .Description {
        Padding: 0px 2px;
        h3 {
            font-weight: bold;
            margin: 0px;
        }

        p {
            font-size: 1.0rem;
            margin: 0px 0px 15px 0px;
        }
        p:last-child {
            margin: 0px 0px 5px 0px;
        }
    }
`