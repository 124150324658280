import React from "react"
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PhotographTypeMark } from "styles/GlobalStyles"
import { CustomContainer } from "styles/CustomContainer.styles"
import PhotoDetailsTable from 'components/PhotoDetailsTable'

import FeatureImage from 'components/FeatureImage'

// import ActivityDetailsTable from "components/ActivityDetailsTable"

// display the activity's featured image it's details table
const PhotoTeaser = ({step, photo}) => {
    var photoText = "Photo #" + step + ":"
    console.log('in PhotoTeaser (#4fifsirj). Photo: ', photo)
    var field_photo_images = photo.relationships.field_photo_images
    console.log('in PhotoTeaser (#4fifsirj). photo.relationships.field_photo_images: ', field_photo_images)
    
    return (
        <CustomContainer>
            <Row>
                <Col className="col" xs={12} md={12}>
                    <PhotographTypeMark id={'photo_' + step}>{photoText}</PhotographTypeMark>
                    <h2>
                        {/* <Link to={photo.fields.slug}>
                            {photo.title}
                        </Link> */}
                        {photo.title}
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col className="col noPadding" xs={12} md={6}>
                    <FeatureImage featureImage={photo} aspectRatio='1x1' />
                    {/* <Img fluid={photo_field.localFile.childImageSharp.fluid } /> */}
                    {/* <Img fluid={acti.relationships.field_activity_feature_photo.relationships.field_photo_images.localFile.childImageSharp.fluid } /> */}
                </Col>
                <Col className="col" xs={12} md={6}>
                    <PhotoDetailsTable photo={photo} detailLevel={"none"} layout={"compact"} />
                    
                </Col>
            </Row>
        </CustomContainer>
    )
}

export default PhotoTeaser